@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";
@import "src/styles/dialogs/core/mixins";

$colors: (
        $font: black,
        $background: $color-I,
        $box-shadow: black,
);

app-card-task-details {
  @mixin flex-display-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ow-dialog {
    display: flex;
    flex-direction: column;
    box-shadow: unset;
    &.window-a {
      padding: multiply-size(10px) multiply-size(32px);

      &.rank-desc {
        padding: multiply-size(10px) multiply-size(32px) multiply-size(36px);
      }
    }
  }

  .last-import-date,
  .date-range {
    position: absolute;
    top: multiply-size(-23px);
    @include archivo-narrow;
    @include text-stroke;
    color: #ffffff;
    text-shadow: unset;
    font-weight: unset;
    font-size:  multiply-size(12px);
    padding-right:  multiply-size(9px);
    padding-left:  multiply-size(10px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00A6D6;
    height:  multiply-size(23px);
    border:  multiply-size(2px) solid black;
  }
  .date-range {
    right: multiply-size(29px);
    background-color: white;
    color: black;
  }

  .title {
    @include mikado;
    font-weight: bold;
    font-size: multiply-size(12px);
    text-align: center;
  }

  .description {
    @include archivo-narrow;
    font-size: multiply-size(10px);
    margin: multiply-size(2px) 0;
    text-align: center;
    max-height: multiply-size(14px);

    &.align-left {
      text-align: left;
    }

    &.no-business {
      margin-top: multiply-size(15px);
    }

    &.rank {
      @include mikado;
      font-size: multiply-size(10px);
      text-align: center;
      font-weight: bold;
      @include absolute-center-horizontal;
      bottom: 34px;

      img {
        height: multiply-size(14px);
        transform: translateY(multiply-size(3px));
      }
    }
  }

  .progress-title {
    font-size: multiply-size(8px);
    @include archivo-narrow;
    text-align: center;
    margin: multiply-size(11px) 0 multiply-size(2px) 0;
  }

  .progress-bar-container {
    position: relative;
    margin-bottom: multiply-size(37px);
    display: flex;
    justify-content: center;

    .progress-container-outer {
      width: multiply-size(319px);
      position: relative;
      .pointer-container {
        overflow: hidden;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        border: multiply-size(2px) solid transparent;
        top: 0;
        transition: 0.7s;
        .pointer {
          height: multiply-size(20px);
          border-right: multiply-size(2px) solid black;
          transition: 0.7s;
        }
      }
    }

    .progress-container {
      //box-sizing: content-box;
      width: 100%;
      height: multiply-size(17px);
      border: multiply-size(2px) solid black;
      margin: 0 auto;
      box-sizing: border-box;
      position: relative;
      border-radius: multiply-size(50px);
      background-color: #dcd7cd;
      box-shadow: none;
      .fill {
        border: none;
        &.custom-color {
          background: linear-gradient(to bottom, #63D0EF 50%, #00A6D6 50%)
        }
      }
    }
    .value-progressbar {
      .percentage {
        margin-top: multiply-size(3px);
      }
    }

    .target-from,
    .target-to {
      @include absolute-center-vertical;
      @include mikado;
      font-weight: bold;
      font-size: multiply-size(10px);
      width: multiply-size(50px);
    }

    .target-to {
      right: multiply-size(-8px);
    }

    .target-from {
      text-align: right;
      left: multiply-size(-8px);
    }

    .value-progressbar {
      font-size: multiply-size(12px);
      @include mikado();
      font-weight: bold;
      position: absolute;
      bottom: multiply-size(10px, "-100% -");
      transform: translateX(-50%);
      transition: 0.7s;
    }
  }

  .scopes-container-v4 {
    display: flex;
    align-items: center;
    justify-content: center;

    .scopes-v4 {
      .scope-v4:first-child > .scope-check > .top {
        display: none;
      }

      .scope-v4:last-child > .scope-check > .bottom {
        display: none;
      }

      .scope-v4 {
        display: flex;
        align-items: center;
        margin: multiply-size(2px) 0;
        position: relative;

        .scope-bar-v4 {
          width: multiply-size(195px);
          height: multiply-size(20px);
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .target-label {
            width: multiply-size(65px);
            height: 100%;
            background-color: #000000;
            color: #ffffff;
            font-size: multiply-size(12px);
            @include mikado;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            border: multiply-size(2px) solid #000;
            border-top-left-radius: multiply-size(12px);
            border-bottom-left-radius: multiply-size(12px);
            box-sizing: border-box;
            padding-top: multiply-size(2px);
          }

          .prizes {
            border: multiply-size(2px) solid #000;
            border-top-right-radius: multiply-size(12px);
            border-bottom-right-radius: multiply-size(12px);
            flex: 1;
            height: 100%;
            box-sizing: border-box;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background-color: #fff;
          }
        }

        .scope-check {
          width: multiply-size(10px);
          height: multiply-size(10px);
          border: multiply-size(2px) solid #000000;
          border-radius: 100%;
          background-color: #fff;
          box-sizing: border-box;
          margin-left: multiply-size(6px);
          position: relative;
          z-index: 2;

          .top,
          .bottom {
            @include absolute-center-horizontal;
            width: multiply-size(2px);
            height: multiply-size(6px);
            background-color: #000000;
            z-index: 1;
          }

          .top {
            top: multiply-size(-8px);
          }

          .bottom {
            bottom: multiply-size(-8px);
          }
        }
      }
    }

    .current-execution {
      margin-left: multiply-size(5px);

      &.achieved-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: multiply-size(196px);
        height: multiply-size(48px);
        box-sizing: border-box;
        border: multiply-size(2px) solid #000000;
        background-color: #fff;
        border-radius: multiply-size(7px);

        p {
          @include archivo-narrow;
          font-weight: bold;
          font-size: multiply-size(10px);
          display: flex;
          margin: 0;

          span {
            @include mikado;
            font-weight: bold;
            display: flex;

            m-ui-currency {
              margin: 0 multiply-size(1px);
            }
          }
        }

        .separator {
          height: multiply-size(1px);
          width: multiply-size(35px);
          background-color: #000000;
          margin: multiply-size(4px) 0;
        }
      }
    }
  }

  .scope-achieved {
    background-color: #a5d600 !important;
  }

  .prizes-container {
    margin-top: multiply-size(15px);
    display: flex;
    justify-content: center;
    align-items: center;

    .prizes-title {
      font-size: multiply-size(11px);
      line-height: 1;
      @include mikado();
      text-align: center;
      margin-right: multiply-size(5px);
    }

    .prize-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .prize-value,
      .prize-info {
        height: multiply-size(20px);
        border-radius: multiply-size(7px);
        border: multiply-size(2px) solid #003366;
        font-size: multiply-size(14px);
        @include mikado;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .prize-value {
        position: relative;
        background: #003366;
        color: #ffffff;
        padding: 0 multiply-size(24px) 0 multiply-size(10px);

        m-ui-currency {
          @include absolute-center-vertical;
          right: multiply-size(-19px);
        }
      }

      .prize-info {
        background: #ffffff;
        color: #003366;
        padding: 0 multiply-size(10px) 0 multiply-size(19px);
      }
    }

    button {
      &.curve {
        margin-left: multiply-size(3px);
        font-size: multiply-size(12px);
        width: multiply-size(20px);
        height: multiply-size(20px);
        color: inherit;

        i {
          text-shadow: none;
        }
      }
    }
  }

  // CARD_TEMPLATE S1S2
  .scopes-v1 {
    display: flex;
    justify-content: center;
    width: 84%;
    margin: 0 auto multiply-size(10px) auto;

    .scope-v1 {
      margin-right: multiply-size(5px);

      &:last-child {
        margin-right: 0;
      }

      .target-v1 {
        border-top-left-radius: multiply-size(7px);
        border-top-right-radius: multiply-size(7px);
        height: multiply-size(18px);
        background-color: #000000;
        color: #ffffff;
        @include mikado;
        font-size: multiply-size(10px);
        padding-top: multiply-size(2px);
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .prizes {
        border-bottom-left-radius: multiply-size(7px);
        border-bottom-right-radius: multiply-size(7px);
        background-color: #fff;
        align-items: flex-start;
        padding-top: multiply-size(6px);
        height: multiply-size(78px);
        box-sizing: border-box;
        border: multiply-size(2px) solid #000000;
        width: multiply-size(40px);
        display: flex;
        justify-content: center;
        .prize {
          display: flex;
          justify-content: center;
          align-items:center;
          flex-direction: column;
          m-ui-currency {
            .icon {
              width: multiply-size(20px);
              height: multiply-size(20px);
            }
            span {
              margin: 0;
              text-shadow: unset;
              color: black;
            }
          }
        }
      }
    }
  }

  .parameters {
    @include archivo-narrow;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .prizes-paid-container {
      display: flex;
      .description-prize{
        display: inline-flex;
        align-items: center;
        border-right: solid multiply-size(1px)black;
        width: fit-content;
        padding-bottom: multiply-size(8px);
        .description-prize-text {
          margin-top: 0;
          margin-bottom: 0;
          font-size: multiply-size(10px);
          margin-left: multiply-size(40px);
          width: multiply-size(100px);
          text-align: end;
        }
        .description-prize-value {
          @include mikado-black;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 0;
          font-size: multiply-size(10px);
          width: multiply-size(30px);
          text-align: end;
        }
        m-ui-currency {
          margin-left: multiply-size(2px);
          margin-right: multiply-size(20px);
        }
      }
      .prize-paid{
        display: inline-flex;
        align-items: center;
        width: fit-content;
        padding-bottom: multiply-size(8px);
        .prize-paid-text {
          margin-top: 0;
          margin-bottom: 0;
          font-size: multiply-size(10px);
          margin-left: multiply-size(10px);
          width: multiply-size(80px);
          text-align: end;
        }
        .prize-paid-value {
          @include mikado-black;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 0;
          font-size: multiply-size(10px);
          width: multiply-size(50px);
          text-align: end;
        }
        m-ui-currency {
          margin-left: multiply-size(2px);
        }
      }
    }
    .maximum, .minimum, .realization, {
      display: inline-flex;
      align-items: center;
      width: fit-content;
      padding-bottom: multiply-size(6px);
      p{
        margin-bottom: 0;
        margin-top: 0;
      }
      .minimum-text, .maximum-text, .realization-text, .bonus-text{
        margin-top: 0;
        margin-bottom: 0;
        font-size: multiply-size(10px);
        margin-left: multiply-size(45px);
        width: multiply-size(95px);
        text-align: end;
      }
      .minimum-value, .maximum-value, .realization-value, .bonus-value{
        @include mikado-black;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
        font-size: multiply-size(10px);
        width: multiply-size(30px);
        text-align: end;
      }
      m-ui-currency{
        margin-left: multiply-size(2px);
      }
    }
    .bonus{
      @include mikado();
      font-size: multiply-size(10px);
      margin-top: multiply-size(20px);
      font-weight: 300;
      display: flex;
      justify-content: center;
      .bonus-value{
        margin-left: multiply-size(4px);
      }
      p{
        margin-bottom: 0;
        margin-top: 0;
      }
      m-ui-currency{
        margin-left: multiply-size(2px);
      }
    }

  }

  .table-container {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .line {
      width: multiply-size(1px);
      height: 100%;
      background: #003366;
      margin: 0 multiply-size(9px);
    }
  }

  table {
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;

    tr {
      td {
        @include archivo-narrow;
        font-size: multiply-size(10px);
        padding: 0 multiply-size(2px);
        font-weight: bold;

        &:first-child {
          text-align: right;
        }

        &:last-child {
          @include mikado;
          text-align: left;
          m-ui-currency {
            span {
              font-family: "Mikado", sans-serif !important;
              font-size: multiply-size(10px) !important;
              margin: 0;
              text-shadow: unset;
              color: black;
            }
          }
        }
      }
    }

  }

  .min-info {
    margin-top: multiply-size(4px);
    font-size: multiply-size(8px);
    @include archivo-narrow;
  }
}

.back-bar-custom {
  @include left-overlap($colors);
  position: absolute;
  left: multiply-size(-22px);
  padding: multiply-size(7px);
  box-sizing: border-box;
  img{
    height: multiply-size(40px);
    width: multiply-size(40px);
  }
  &.level1{
    top: multiply-size(15px);
    img{
      position: relative;
      top: multiply-size(5px);
    }
  }
  &.level2{
    top: multiply-size(67px);
  }
}

