@import "src/styles/functions";

app-custom-login {
  @import "../../../styles/core/styles";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  .logo {
    margin: multiply-size(15px) 0 multiply-size(10px);
  }

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }
  }

  .control {
    &.control-checkbox {
      &.remember-me-container {
        margin-top: multiply-size(20px);
        align-items: center;

        label {
          padding: 0;
          padding-left: multiply-size(8px);
        }
      }
    }
  }

  .buttons {
    &:nth-child(1) {
      margin-top: multiply-size(25px);

      button {
        margin-bottom: multiply-size(5px);
      }
    }

    &:nth-child(2n + 2) {
      margin-top: multiply-size(15px);
      min-height: multiply-size(32px);

      button {
        margin-bottom: 0;
      }
    }
  }

  @import "custom/styles";
}
