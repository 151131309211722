& {
  min-height: 100%;
  justify-content: center;
}

.logo {
  margin: multiply-size(15px) 0 multiply-size(10px);
}

.title {
  margin: 0;
  text-align: center;
}

p.invalid-form {
  margin-top: multiply-size(15px);
}
