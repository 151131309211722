@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-business-cards {
  pointer-events: all;
  @include absolute-center-horizontal;
  bottom: 0;
  z-index: 1;

  .laboratory-scene &, .path-scene &, .tech-tree-scene &, .branch-scene & {
    display: none;
  }

  #{$context-desktop} & {
    --multiply: 2;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  button.primary.square.small {
    i {
      font-size: multiply-size(10px);
    }

    img {
      width: multiply-size(40px);
      height: multiply-size(40px);
    }
  }

  .options {
    margin: 0 0 multiply-size(8px) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: multiply-size(400px);

    .left, .right {
      display: flex;
      align-items: center;
    }

    .right {
      gap: multiply-size(8px);
      span {
        margin: 0 multiply-size(6px);
      }

      button {
        &.margin {
          margin-right: multiply-size(10px);
        }

        &.hide {
          margin-right: 0;
          margin-left: multiply-size(10px);
        }
      }
    }

    .left {
      button {
        margin-right: multiply-size(10px);
      }
    }

    .date {
      @include mikado();
      font-size: multiply-size(10px);
      color: $color-I;
      @include text-stroke;
      font-weight: bold;
      transform: translateY(multiply-size(2px));
      text-align: center;
      min-width: multiply-size(90px);

      loading{
        position: relative;
        top: multiply-size(-10px);
      }
      div {
        display: inline-block;
      }
    }
  }

  .cards {
    display: flex;
    overflow: hidden;
    transition: .3s;
    justify-content: center;
    gap: multiply-size(5px);

    & > * {
      margin-right: multiply-size(5px);

      &:last-child {
        margin-right: 0;
      }
    }

    &.hide {
      height: multiply-size(38px);
    }
  }

  button.mockup {
    position: relative;

    span {
      position: absolute;
      background: red;
      border-radius: multiply-size(20px);
      font-size: multiply-size(9px);
      font-weight: bold;
      width: multiply-size(13px);
      height: multiply-size(13px);
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: none;
      right: multiply-size(-12px);
      top: multiply-size(-7px);
      font-family: "Archivo Narrow", sans-serif;
      color: white;
    }
  }
}
