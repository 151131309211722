@import "src/styles/functions";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "helpers";
@import "forms";
@import "tooltip/core/tooltip";
@import "progressbars/core/progressbars";
@import "dropdown";
@import "icons/core/icons";
@import "icons/custom/ow-icons";
@import "animations";
@import "tables";
@import "slider";
@import "radio-buttons";
@import "tabs";
@import "buttons/core/base";
@import "dialogs/core/base";
@import "swiper/swiper";

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  --multiply: 1;
}

//#game {
//  min-height: 100vh;
//}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.game-gui-window {
  padding: multiply-size(10px);
  background-color: rgba(255, 255, 255, 0.2);
  border: solid multiply-size(1px) $color-I;
}

*:focus {
  outline: none !important;
}

*::-moz-focus-inner {
  border: none;
}

a,
a:focus,
a:hover,
a:visited {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.source-data-date {
  display: inline-block;
  width: 100%;
  text-align: right;
  font-weight: bold;
  font-size: multiply-size(12px);
}

.text {
  margin-top: multiply-size(15px);
  @include futura-heavy;
  font-size: multiply-size(10px);
  line-height: multiply-size(15px);
}

.not-met,
.color-error {
  color: #ffd10d;
  //opacity: 0.5;
}

.color-green {
  color: $color-text-green;
}

.color-primary {
  color: $color-H;
}

.color-secondary {
  color: $color-B;
}

.color-secondary-i {
  color: $color-B !important;
}

.cleared {
  width: multiply-size(120px);
  height: multiply-size(28px);
  position: absolute;
  bottom: 0;
  right: multiply-size(65px);
  @include mikado;
  font-size: multiply-size(12px);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-button-base-primary;
  padding-top: multiply-size(5px);
  box-sizing: border-box;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: multiply-size(1000px);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(multiply-size(-1px), 0, 0);
  }

  20%,
  80% {
    transform: translate3d(multiply-size(2px), 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(multiply-size(-4px), 0, 0);
  }

  40%,
  60% {
    transform: translate3d(multiply-size(4px), 0, 0);
  }
}

app-game-debug {
  .close-button {
    display: none !important;
  }
}

body {
  #game-debug,
  .pdebug {
    visibility: hidden;
    z-index: 9999999;
  }
}

.paragraph {
  margin-top: multiply-size(27px);
  @include archivo-narrow;
  font-size: multiply-size(16px);
  line-height: multiply-size(18px);
}

.big {
  font-size: multiply-size(16px);
  font-family: $font-futura-heavy;

  &.secondary {
    color: $color-B;
  }
}

.progress-container {
  background-color: $color-P;
  box-shadow: inset multiply-size(1px) multiply-size(-2px) 0 0 $color-I;
  overflow: hidden;

  .fill {
    width: 0;
    transition: 0.7s;
    max-width: 100%;
    height: 100%;
    border-right: multiply-size(1px) solid $color-K;

    &.one {
      background: linear-gradient(to bottom, #70b500 50%, #a1d200 50%);
    }

    &.two {
      background: linear-gradient(to bottom, #66d2d8 50%, #00b4be 50%);
    }

    &.no-min {
      background: linear-gradient(to bottom, #e2001a 50%, #f34155 50%);
    }

    &.have-min-valid-true,
    &.no-min-valid {
      &.realization-down-100 {
        &.card-template-1 {
          background: linear-gradient(to bottom, #e25f00 50%, #f37f46 50%);
        }
      }

      &.realization-up-100 {
        background: linear-gradient(to bottom, #70b500 50%, #a1d200 50%);
      }
    }

    &.realization-down-100 {
      &.card-template-2 {
        background: linear-gradient(to bottom, #e2001a 50%, #f34155 50%);
      }
    }

    &.have-min-valid-false {
      &.realization-down-100 {
        &.card-template-1 {
          background: linear-gradient(to bottom, #e2001a 50%, #f34155 50%);
        }
      }

      &.realization-up-100 {
        &.card-template-1 {
          background: linear-gradient(to bottom, #e25f00 50%, #f37f46 50%);
        }
      }
    }
  }
}

table {
  tr {
    td,
    th {
      padding: 0;

      &.full-width {
        width: 100%;
      }

      &.no-wrap {
        white-space: nowrap;
      }
    }
  }
}

mat-dialog-container {
  & > * {
    .desktop .cdk-overlay-pane:not(.no-scale) & {
      display: block;
      overflow: visible;
      --multiply: 2;
    }

    .tablet .cdk-overlay-pane:not(.no-scale) & {
      display: block;
      --multiply: 1.5;
    }
  }

  .photo-gallery-mobile & {
    @media only screen and (max-width: multiply-size(767px)) {
      // no-multiply-size
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .mdc-dialog__surface {
    overflow-y: unset;
    background: transparent;
  }

  .mat-mdc-dialog-container {
    background: transparent;
  }
}

.event-more {
  font-weight: bold;
  cursor: pointer;
}

// TOOLTIP FOR RESOURCE FROM STRING
.img-box {
  position: relative;

  &:hover {
    .mdc-tooltip.resource-from-string {
      display: block !important;
    }
  }

  .mdc-tooltip.resource-from-string {
    @include absolute-center-horizontal;
    top: -50px;
    white-space: nowrap;
    box-sizing: border-box;
    margin: 0;
    display: none;
    border: solid black multiply-size(1px);
    border-radius: multiply-size(4px);
    padding: multiply-size(2px);
    background: white;
    font-size: multiply-size(8px);
  }
}

swiper-container {
  max-width: 100%;
  max-height: 100%;
}

.swiper-pagination-bullet-test {
  background-color: #ebe5d5;
  border-radius: calc(50px * var(--multiply));
  transition: .3s;
  transform: scale(0);
  transform-origin: 50% 50%;
  height: 14px;
  margin: 0 2px;
  width: 14px;
  border: 2px solid #000000;

  &.swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.4);
  }

  &.swiper-pagination-bullet-active-prev {
    transform: scale(0.7);
  }

  &.swiper-pagination-bullet-active {
    &.swiper-pagination-bullet-active-main {
      transform: scale(1);
      width: 18px;
      height: 18px;
      background-color: #77cc4f;
      margin: 0 2px;
      border: 2px solid #000000;
    }
  }

  &.swiper-pagination-bullet-active-next {
    transform: scale(0.7);
  }

  &.swiper-pagination-bullet-active-next-next {
    transform: scale(0.4);
  }
}

@import "../styles-csp-structure/styles";
@import "./custom/styles";
