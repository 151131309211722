@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";
@import "variables";

@mixin flex-center {
  display: flex;
  align-items: center;
}

//  -------------------->
//  STOCK A ------------>
//  -------------------->
.stock-a {
  flex-direction: column;
  @include flex-center;

  .value {
    margin-top: multiply-size(5px);
  }
}

//  -------------------->
//  STOCK B ------------>
//  -------------------->
.stock-b {
  flex-direction: column;
  @include flex-center;

  .value {
    margin-top: multiply-size(5px);
  }
}

//  -------------------->
// STOCK C ------------->
//  -------------------->
.stock-c {
  padding-left: multiply-size(10px);

  .icon {
    .value {
      padding-left: multiply-size(2px);
      padding-top: multiply-size(2px);
      left: multiply-size(-10px);
      @include absolute-center-vertical;
      color: $color-stock-value-2;
    }
  }

  &.lack {
    .value {
      color: $color-stock-value-2;
    }
  }
}

//  -------------------->
// STOCK D ------------->
//  -------------------->
.stock-d {
  width: multiply-size(60px);
  height: multiply-size(24px);
  padding-left: multiply-size(11px);
  box-sizing: border-box;
  position: relative;
  justify-content: flex-end;
  @include flex-center;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .box {
    width: multiply-size(48px);
    height: multiply-size(19px);
    background: $color-stock-box-1;
    border: multiply-size(1px) solid $color-stock-box-1;
    justify-content: flex-end;
    padding-right: multiply-size(4px);
    padding-top: multiply-size(2px);
    box-sizing: border-box;
    @include flex-center;

    .value {
      font-size: multiply-size(10px);
      color: $color-stock-value-2;
    }
  }
}

//  -------------------->
//  STOCK E ------------>
//  -------------------->
.stock-e {
  .icon {
    .fa-exclamation {
      left: multiply-size(1px);
    }

    .value {
      position: absolute;
      right: multiply-size(-5px);
      bottom: multiply-size(1px);
      color: $color-stock-value-2;
    }
  }

  &.lack {
    .value {
      color: $color-stock-value-2;
    }
  }
}

//  -------------------->
//  STOCK F ------------>
//  -------------------->
.stock-f {
  .icon {
    .value {
      position: absolute;
      right: multiply-size(-4px);
      bottom: multiply-size(2px);
      color: $color-stock-value-2;
    }

    .fa-exclamation {
      left: multiply-size(1px);
    }
  }

  &.lack {
    .value {
      color: $color-stock-value-2;
    }
  }
}

//  -------------------->
//  STOCK G ------------>
//  -------------------->
.stock-g {
  @include flex-center;

  .value {
    margin-left: multiply-size(2px);
    font-size: multiply-size(10px);
    z-index: 2;
  }
}

//  -------------------->
//  STOCK H ------------>
//  -------------------->
.stock-h {
  @include flex-center;

  .icon {
    margin-right: multiply-size(1px);
    width: multiply-size(14px);
    height: multiply-size(14px);
  }

  .value {
    margin-left: multiply-size(2px);
    font-size: multiply-size(8px);
    z-index: 2;
  }
}

//  -------------------->
//  STOCK I ------------>
//  -------------------->
.stock-i {
  @include flex-center;

  .value {
    text-indent: multiply-size(-3px);
    z-index: 2;
  }
}

//  -------------------->
//  STOCK J ------------>
//  -------------------->
.stock-j {
  @include flex-center;

  .value {
    margin-right: multiply-size(2px);
    z-index: 2;
    @include mikado();
  }

  .icon {
    margin-right: multiply-size(1px);
    width: multiply-size(14px);
    height: multiply-size(14px);
  }
}

//  -------------------->
//  STOCK K ------------>
//  -------------------->
.stock-k {
  @include flex-center;

  .icon {
    margin-right: multiply-size(1px);
    width: multiply-size(14px);
    height: multiply-size(14px);
  }

  .value {
    margin-left: multiply-size(2px);
    font-size: multiply-size(8px);
    z-index: 2;
  }
}

//  -------------------->
//  BASE STYLE --------->
//  -------------------->
.icon {
  background-repeat: no-repeat;
  background-size: contain;
  font-style: normal;
  display: inline-block;
  position: relative;
}

.stock-a,
.stock-d,
.stock-e,
.stock-g {
  .icon {
    width: multiply-size(25px);
    height: multiply-size(25px);
  }
}

.stock-b,
.stock-c,
.stock-f,
.stock-i {
  .icon {
    width: multiply-size(38px);
    height: multiply-size(38px);
  }
}

.icon-lack {
  visibility: hidden;
}

.lack {
  .icon {
    @include drop-shadow($color-stock-error);
  }

  .value {
    color: $color-stock-error;
  }

  .icon-lack {
    visibility: visible;
  }
}

.fa-exclamation {
  position: absolute;
  right: multiply-size(1px);
  bottom: multiply-size(1px);
  pointer-events: none;
  color: $color-stock-error;
  @include text-stroke;
}

.stock-a,
.stock-d,
.stock-e,
.stock-g,
.stock-j {
  .fa-exclamation {
    font-size: multiply-size(15px);
  }
}

.stock-b,
.stock-c,
.stock-f,
.stock-i {
  .fa-exclamation {
    font-size: multiply-size(19px);
  }
}

.stock-h {
  .fa-exclamation {
    font-size: multiply-size(10px);
  }
}

.value {
  color: $color-stock-value-1;
  font-weight: 700;
  @include mikado();
  @include text-stroke;
  white-space: nowrap;
}

.stock-a,
.stock-b,
.stock-c,
.stock-e,
.stock-j {
  .value {
    font-size: multiply-size(10px);
  }
}

.stock-f,
.stock-i {
  .value {
    font-size: multiply-size(14px);
  }
}

.size-76 {
  .icon {
    width: multiply-size(76px);
    height: multiply-size(76px);
  }
}

.chest {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: multiply-size(38px);
    height: multiply-size(38px);
  }

  .value {
    transform: translateY(multiply-size(-10px));
    font-size: multiply-size(13px);
  }
}

@import "custom/stock";
