@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-choose-task {
  .ow-dialog {
    display: flex;
    flex-direction: column;

    &.window-a {
      padding: multiply-size(14px) multiply-size(32px);
    }
  }

  .ps-content {
    padding-right: multiply-size(17px);
  }

  .title {
    @include mikado();
    font-weight: bold;
    font-size: multiply-size(13px);
    text-align: center;
    text-transform: uppercase;
  }

  .description {
    @include mikado();
    font-size: multiply-size(12px);
    margin: multiply-size(6px) 0;
    text-align: center;
    max-height: multiply-size(35px);
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      td {
        padding: 0 multiply-size(9px);
        @include mikado();
        font-size: multiply-size(12px);
        font-weight: bold;
        text-align: left;
        height: multiply-size(30px);
        cursor: pointer;

        .flex-center {
          justify-content: flex-start;

          m-ui-currency {
            margin: 0 multiply-size(2px);
          }

          i {
            margin-right: multiply-size(3px);
            transform: translateY(multiply-size(-1px))
          }
        }

        &:first-child {
          padding-left: multiply-size(13px);
          font-weight: initial;
        }

        &:last-child {
          padding-right: multiply-size(13px);
        }
      }

      &:nth-child(odd) {
        background: rgba($color-I, 0.38);
      }
    }
  }

  ow-perfect-scrollbar {
    margin-top: multiply-size(10px);
  }
}
