@import "src/styles/functions";

.global-dialog {
  width: multiply-size(520px);
}

.missions {
  .window-b {
    padding: multiply-size(24px) multiply-size(2px);

    .new {
      span {
        color: $color-B !important;
      }
    }

    &.mission-completed {
      @include drop-shadow($color-B, 2, 3, 4);

      i {
        &.check {
          display: block;
        }
      }
    }
  }
}
