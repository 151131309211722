@import "src/styles/functions";
@import "src/styles/mixins";

app-question-list {
  .ow-dialog {
    display: flex;
    flex-direction: column;

    &.window-a {
      padding: multiply-size(13px) multiply-size(35px) multiply-size(15px) multiply-size(35px);
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      @include archivo-narrow;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }

    th {
      font-size: multiply-size(10px);
      font-weight: normal;
      padding-bottom: multiply-size(4px);
      &:last-child {
        min-width: multiply-size(80px);
      }
    }

    td {
      padding: multiply-size(7px) multiply-size(4px);
      font-weight: bold;
      font-size: multiply-size(11px);
    }

    tbody {
      tr {
        cursor: pointer;

        &.opacity {
          opacity: 0.6;
        }

        &:hover {
          td {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
      m-ui-currency{
        .mat-mdc-tooltip-trigger{
          span{
            text-shadow: unset;
            color: black;
          }
        }
      }
    }

    tr {
      td {
        transition: .3s;

        .prizes {
          & > * {
            margin: 0 multiply-size(4px);
          }
        }
      }

      &:not(:last-child) {
        td {
          border-bottom: multiply-size(1px) solid #15591F;
        }
      }
    }
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .categories-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: multiply-size(5px);

    .each-category {
      display: flex;
      align-items: center;
      margin-right: multiply-size(10px);
      cursor: pointer;

      button {
        margin-right: multiply-size(8px);
      }

      span {
        @include mikado();
        font-weight: bold;
        font-size: multiply-size(10px);
      }
    }
  }

  .nav {
    @include absolute-center-horizontal;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: multiply-size(-30px);

    .month {
      @include mikado();
      @include text-stroke;
      color: $color-I;
      font-weight: bold;
      font-size: multiply-size(12px);
      margin: 0 multiply-size(10px);
      width: multiply-size(110px);
      text-align: center;
    }
  }

  .daily-questions-empty-page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: multiply-size(12px);
    font-weight:bold ;
    @include archivo-narrow;
  }

}
