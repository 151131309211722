@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-mission-transfer {
  @import "variables";

  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .form-container {
    display: flex;
  }

  .product-in-glow-box {
    width: multiply-size(58px);
    height: multiply-size(55px);
    margin: multiply-size(6px) multiply-size(5px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mikado();
    font-weight: bold;
    box-sizing: border-box;
    padding: multiply-size(4px);

    .product-icon {
      width: multiply-size(38px);
      height: multiply-size(38px);
      background-size: contain;
      background-repeat: no-repeat;
    }

    .product-balance {
      position: absolute;
      bottom: multiply-size(-8px);
      font-size: multiply-size(10px);
      left: multiply-size(9px);
      text-align: center;
      width: 70%;
    }

    .clip-path {
      position: absolute;
      top: multiply-size(-1px);
      left: multiply-size(-2px);
      height: 100%;
      width: 100%;
      border: multiply-size(2px) solid $color-K;
      border-radius: multiply-size(10px);
      clip-path: polygon(
        -3% 107.06%,
        -3% -2%,
        106.72% -3%,
        104.03% 109.89%,
        76.01% 109.32%,
        75.74% 98.35%,
        86.16% 96.93%,
        92% 11%,
        7% 11%,
        12.85% 96.65%,
        24.42% 98.06%,
        26.3% 108.48%
      );
    }
  }

  .product-name,
  .summary {
    @include text-stroke;
    word-spacing: multiply-size(2px);
    color: $color-I;
  }

  .product-name {
    text-transform: uppercase;
    @include mikado-black;
    font-size: multiply-size(12px);
    margin: multiply-size(15px) 0 multiply-size(21px) 0;
  }

  .summary {
    margin-top: multiply-size(27px);
    @include mikado;
    font-size: multiply-size(12px);
  }

  form {
    text-align: center;
    width: unset;

    input {
      background: $color-I;
      font-family: MyriadPro, sans-serif;
    }
  }

  button.only-border,
  input[type="number"] {
    margin: 0 multiply-size(5px);
    border: multiply-size(2px) solid $color-K;
    height: multiply-size(28px);
    width: multiply-size(128px);
    box-sizing: border-box;
    padding: 0 multiply-size(10px);
    font-size: multiply-size(12px);
    color: $color-K;
    text-align: center;
    border-radius: multiply-size(7px);
    @include mikado;
    text-shadow: none;
  }

  button.no-hover-blur {
    filter: none;
  }

  .error {
    text-align: center;
    @include archivo-narrow;
  }

  button {
    &.mobile-square {
      font-size: multiply-size(12px);
      border: multiply-size(1px) solid $color-K;
      width: multiply-size(40px);
      height: multiply-size(40px);
      border-radius: multiply-size(7px);
    }

    .far {
      color: $color-K;
      text-shadow: none;
    }
  }

  @import "custom/style";
}
