@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-hud-resources-custom {
  @import "variables";

  //  ------------------------
  //  PLAYER CURRENCY BALANCES
  //  ------------------------
  pointer-events: all;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 0 0 0 0;

  #{$context-desktop} & {
    --multiply: 2;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  button {
    &.bar {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: multiply-size(66px);
      height: multiply-size(20px);
      justify-content: flex-end;
      padding-right: multiply-size(3px);
      border-radius: 0;
      @include mikado();
      font-size: multiply-size(12px);
      line-height: multiply-size(12px);
      background-color: $bgc-bar;
      font-weight: 700;
      color: $font-color;
      margin-bottom: multiply-size(20px);

      &:first-child {
        margin-top: multiply-size(13px);
      }

      &:after {
        content: '';
        position: absolute;
        top: multiply-size(-8px);
        right: 0;
        bottom: multiply-size(-8px);
        left: 0;
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: multiply-size(30px);
      height: multiply-size(30px);
      position: absolute;
      left: 0;
      transform: translateX(-50%);
      background-size: contain;
    }
  }

  &.initial-cursor {
    cursor: initial;
  }
}
