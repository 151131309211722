@import "src/styles/functions";
@import "src/styles/mixins";

app-reward-codes {
  ow-perfect-scrollbar {
    .ps-content {
      @media only screen and (min-width: 768px) {
        padding-right: multiply-size(18px);
      }
    }
  }

  @include archivo-narrow();

  .code-wrapper {
    text-align: center;

    span {
      font-size: multiply-size(12px);
    }

    .error-message {
      font-size: multiply-size(10px);
      color: #ff0909;
      margin: multiply-size(5px) 0;
      min-height: multiply-size(11px);
    }

    .input-wrapper {
      display: flex;
      gap: multiply-size(20px);
      margin: multiply-size(14px) 0;

      .code {
        width: multiply-size(150px);
        height: multiply-size(31px);
        border-radius: multiply-size(5px);
        border: multiply-size(2px) solid #000;
      }
    }
  }

  .history-label {
    display: flex;
    gap: multiply-size(4px);
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: multiply-size(10px);

    span {
      display: flex;
      font-size: multiply-size(10px);
    }

    .separator {
      height: multiply-size(1px);
      width: 100%;
      background: #000;
      flex: 1;
    }
  }

  .history-wrapper {
    @include archivo-narrow();
    font-size: multiply-size(10px);
    padding-left: multiply-size(5px);

    .row {
      display: flex;
      border-bottom: multiply-size(1px) solid #dcd9d0;
      height: multiply-size(20px);
      justify-content: space-between;
      align-items: center;
      gap: multiply-size(20px);
      padding: 0 multiply-size(6px);

      div {
        display: flex;
      }

      .content {
        flex: 1;
        padding: multiply-size(5px) 0;
      }

      .prize {
        m-ui-currency {
          .stock-j {
            .value {
              text-shadow: none;
              color: #000;
              @include archivo-narrow();
            }
          }
        }
        m-ui-product {
          .stock-j {
            .value {
              text-shadow: none;
              color: #000;
              @include archivo-narrow();
            }
          }
        }
      }
    }
  }

  .ow-dialog {
    display: flex;
    flex-direction: column;

    &.window-a {
      padding: multiply-size(10px) multiply-size(30px) multiply-size(10px);
    }
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .no-history-data {
    padding-bottom: multiply-size(10px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: multiply-size(12px);
    font-weight: 700;
    @include archivo-narrow;
  }
}
