@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-basic-actions {
  $sticky-actions: $color-R;

  .ow-dialog {
    display: flex;
    flex-direction: column;

    &.window-a {
      padding: multiply-size(13px) multiply-size(35px) multiply-size(15px) multiply-size(35px);
    }

    .menu-right {
      justify-content: flex-end;
      padding: multiply-size(5px) 0;
      box-sizing: border-box;
    }
  }

  .title {
    @include mikado();
    font-weight: bold;
    font-size: multiply-size(12px);
    text-align: center;
    text-transform: uppercase;
  }

  .description {
    @include mikado();
    font-size: multiply-size(12px);
    margin: multiply-size(6px) 0;
    text-align: center;
  }

  .navigate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mikado();
    font-weight: bold;

    .current {
      font-size: multiply-size(12px);
      text-align: center;
      line-height: multiply-size(12px);
      flex: 3;
    }

    .prev-container, .next-container {
      flex: 1;
    }

    .date {
      font-size: multiply-size(10px);
    }

    .prev-container {
      .date {
        margin-left: multiply-size(5px);
      }
    }

    .next-container {
      .date {
        margin-right: multiply-size(5px);
      }
    }
  }

  i {
    .fa-list {
      font-size: multiply-size(12px);
    }
  }

  ow-perfect-scrollbar {
    margin-top: multiply-size(8px);
  }

  table {
    @include mikado();
    border-spacing: multiply-size(2px) 0;

    tr {
      th, td {
        padding: 0 multiply-size(9px);

        &.sticky {
          color: $sticky-actions;
          font-weight: bold;

          i {
            margin-right: multiply-size(2px);
          }
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }

        &.border-color {
          border-top-width: 0;
          border-bottom-width: 0;
          text-align: center;
          font-weight: bold;
          font-size: multiply-size(12px);
        }
      }

      th {
        font-size: multiply-size(8px);
        font-weight: normal;
        padding-top: multiply-size(2px);
        padding-bottom: multiply-size(2px);
      }

      td {
        font-size: multiply-size(10px);
        height: multiply-size(20px);

        .prizes {
          display: flex;
          justify-content: flex-end;

          .prize {
            margin-right: multiply-size(5px);
            font-weight: bold;
            font-size: multiply-size(12px);
            justify-content: flex-end;

            .icon {
              margin-left: multiply-size(3px);
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.summary {
        td {
          font-size: multiply-size(12px);
          font-weight: bold;
          height: multiply-size(30px);
          border-top: multiply-size(1px) solid $color-K !important;

          .limit-info {
            font-size: multiply-size(8px);
            font-weight: normal;
          }

          &:first-child {
            text-align: right;
          }

          &.border-color {
            border-left-width: 0;
            border-right-width: 0;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd):not(.summary) {
          background: rgba($color-I, 0.38);
        }
      }
    }
  }

  .line-height-v1 {
    line-height: multiply-size(10px)
  }

  ow-perfect-scrollbar {
    .ps-content {
      padding-right: multiply-size(9px);
    }
  }
}
