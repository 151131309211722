@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-warehouse-sale {
  .ow-dialog {
    padding: multiply-size(22px) multiply-size(18px) multiply-size(22px) multiply-size(27px);
  }

  .exit-bar {
    top: multiply-size(-2px) !important;
    right: multiply-size(-6px) !important;
  }

  .exit.normal {
    i {
      font-size: multiply-size(12px) !important;
    }
  }

  .sales {
    width: 100%;
    height: multiply-size(187px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-thead {
    table {
      tbody {
        tr {
          height: multiply-size(20px);

          td {
            @include archivo-narrow();
            font-size: multiply-size(7px);
            white-space: nowrap;
            border-bottom: none;

            &.name {
              text-align: left;
            }
          }
        }
      }
    }
  }

  table {
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
    //width: 100%;
    height: 100%;

    tbody {
      tr {
        height: multiply-size(41px);

        &:not(:last-child) {
          td {
            border-bottom: multiply-size(1px) solid $color-H;
          }
        }

        td {
          @include mikado();
          font-size: multiply-size(10px);
          text-align: center;

          &.red-txt {
            color: #db3105 !important;
          }

          img {
            width: multiply-size(25px);
            height: multiply-size(25px);
            object-fit: contain;
          }

          &.w-50 {
            width: multiply-size(50px);
          }

          &.w-57 {
            width: multiply-size(57px);
          }

          &.w-70 {
            width: multiply-size(70px);
          }

          &.w-38 {
            width: multiply-size(37px);
          }

          &.name {
            @include archivo-narrow();
            font-size: multiply-size(8px);
            text-align: left;
          }

          &.sell {
            background-color: #e0e4d8;
            border-bottom: none !important;
            position: relative;

            .border-bg {
              @include absolute-center-horizontal;
              bottom: -1px;
              z-index: 4;
              background-color: #e0e4d8;

              .border {
                width: 80%;
                height: 2px;
                background-color: #000000;
              }
            }

            .absolute {
              @include absolute-center-horizontal;
              top: multiply-size(-11px);
            }
          }

          button.base {
            width: multiply-size(53px) !important;
            min-width: multiply-size(53px) !important;
            height: multiply-size(28px) !important;
            margin: 0 auto;

            .stock-j {
              .value {
                color: $color-I !important;
              }
            }
          }
        }
      }
    }
  }

  .sum {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mikado();
    font-size: multiply-size(12px);
    margin-top: multiply-size(15px);
    box-sizing: border-box;
    padding-right: multiply-size(83px);

    .stock-j {
      margin-left: multiply-size(22px);

      .value {
        font-size: multiply-size(12px);
      }
    }
  }

  .display-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stock-j {
    .value {
      @include mikado();
      align-self: flex-end;
      padding-top: multiply-size(2px);
    }
  }

  .border-white {
    border-right: multiply-size(10px) solid white;
  }

  //ow-perfect-scrollbar .ps-content {
  //  padding-right: multiply-size(12px) !important;
  //}

  .no-item {
    @include mikado();
    font-size: multiply-size(12px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
