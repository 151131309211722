@import "src/styles/functions";
@import "src/styles/variables/core/variables";

.product-in-glow-box {
  .clip-path {
    border: multiply-size(2px) solid $color-K;
  }
}

input[type="number"] {
  border: multiply-size(2px) solid $color-K;
  color: $color-K;
}

button.only-border {
  border: multiply-size(2px) solid $color-K;
  color: $color-K;
}

.error {
  @include mikado;
  text-transform: uppercase;
  font-size: multiply-size(13px);
  font-weight: bold;
  color: $color-R;
}
