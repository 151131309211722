@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: #000000,
  $border: #000000,
  $background: (
    $normal: #74C100,
    $active: #3E7103,
    $hover: #9BDF35,
  ),
  $box-shadow: (
    $normal: #3E7103,
    $active: #568E02,
    $hover: #74C100,
  ),
  $font: (
    $normal: #FFFFFF,
    $active: #9BDF35,
    $hover: #FFFFFF,
  )
);

&.secondary {
  @include generate-schema-button-color($colors)
}
