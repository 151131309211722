app-ranking-details-world {
  @import "../../styles/styles";

  m-ui-close-button{

  }
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    text-align: center;
    font-size: multiply-size(14px);
    @include archivo-narrow();
    font-weight: bold;
    justify-content: center;

  }

  .rank-details-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .rank-condition-header{
    font-size: multiply-size(9px);
    margin-top: 0;
    margin-bottom: multiply-size(6px);
    font-weight:100;
    @include archivo-narrow();
    margin-left: multiply-size(150px);
  }
  ow-perfect-scrollbar{
    height: multiply-size(173px);
    .ps-content{
      width: multiply-size(414px);
      padding-right: multiply-size(16px);
    }
    table{
      box-sizing: border-box;
      border-collapse: collapse;
      width: 100%;
      thead{
        th{
          font-size: multiply-size(9px);
          @include archivo-narrow;
          text-align: right;
        }
      }
      tbody{
        tr{
          height: multiply-size(20px);
          @include archivo-narrow;
          font-size: multiply-size(10px);
          td{
            border-bottom: solid multiply-size(1px) #DCD9D0;
          }
          .rank-position{
            padding: 0;
            width: multiply-size(20px);
          }
          .rank-name{
            padding: 0;
            width: multiply-size(130px);
          }
          .rank-condition{
            padding: 0;
            width: multiply-size(60px);
          }
          .rank-value1{
            font-weight: bold;
            padding: 0;
            width: multiply-size(94px);
            text-align: end;
            m-ui-currency{
              z-index: unset;
              .stock-j{
                justify-content: end;
              }
              span{
                text-shadow: unset;
                color: black;
              }
            }
          }
          .rank-value2{
            font-weight: bold;
            padding: 0;
            min-width: multiply-size(96px);
            width: multiply-size(110px);
            text-align: end;
            m-ui-product, m-ui-currency{
              z-index: unset;
              .stock-j{
                justify-content: end;
              }
              span{
                text-shadow: unset;
                color: black;
              }
            }
          }
        }
      }
    }
    &.without-my-position{
      height: multiply-size(230px);
    }
  }
  .black-line{
    width: multiply-size(414px);
    background-color: black;
    height: multiply-size(1px);
    margin-bottom: multiply-size(6px);
  }
  .my-position-label{
    margin-bottom: 0;
    font-size: multiply-size(10px);
    height: multiply-size(20px);
    display: flex;
    align-items: center;
    @include archivo-narrow;
  }
  .my-position-data{
    box-sizing: border-box;
    border-collapse: collapse;
    width: multiply-size(414px);
    tbody{
      tr{
        height: multiply-size(20px);
        @include archivo-narrow;
        font-size: multiply-size(10px);
        .rank-position{
          padding: 0;
          width: multiply-size(20px);
        }
        .rank-name{
          padding: 0;
          width: multiply-size(130px);
        }
        .rank-condition{
          padding: 0;
          width: multiply-size(60px);
        }
        .rank-value1{
          font-weight: bold;
          padding: 0;
          width: multiply-size(94px);
          text-align: end;
          m-ui-currency{
            z-index: unset;
            .stock-j{
              justify-content: end;
            }
            span{
              text-shadow: unset;
              color: black;
            }
          }
        }
        .rank-value2{
          font-weight: bold;
          padding: 0;
          width: multiply-size(110px);
          min-width: multiply-size(96px);
          text-align: end;
          m-ui-currency, m-ui-product{
            z-index: unset;
            .stock-j{
              justify-content: end;
            }
            span{
              text-shadow: unset;
              color: black;
            }
          }
        }
      }
    }
  }
}
