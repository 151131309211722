@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-ranking-list {
  .window-a {
    padding: multiply-size(14px) multiply-size(38px) multiply-size(22px) multiply-size(30px);
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    text-align: center;
    font-size: multiply-size(14px);
    @include archivo-narrow();
    font-weight: bold;
    justify-content: center;

  }

  ow-perfect-scrollbar {
    height: 100%;
    .ps-content{
      width: multiply-size(414px);
      padding-right: multiply-size(16px);
    }
    .ranking-name-row{
      width: multiply-size(255px);
    }
    .ps-content {
      @include archivo-narrow;
      font-size: multiply-size(10px);
      text-align: justify;
    }
    &.ps-bottom-border {
      &:before {
        background-color: white;
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {

      td {
        @include mikado;
        font-weight: normal;
        font-size: multiply-size(12px);
        text-align: left;
        height: multiply-size(40px);
        border-bottom: multiply-size(1px) solid #DCD9D0;

        .status-rank {
          @include mikado;
          font-size: multiply-size(12px);
          @include archivo-narrow;

          &.is-active {
            color: black;
          }

          &.is-cleared {
            color: #ff001d;
          }
        }

        &:first-child {
          padding-left: 0px;
          p{
            margin-bottom: multiply-size(3px);
            margin-top: multiply-size(3px);
            height: multiply-size(40px);
            display: flex;
            align-items: center;
            border-left: solid multiply-size(3px) #D5025E;
            padding-left: multiply-size(14px);
            font-weight: lighter;
            @include archivo-narrow;
            font-size: multiply-size(12px);
          }
        }

        &:last-child {
          padding-right: multiply-size(10px);
        }

        .buttons-container {
          display: flex;
          justify-content: flex-end;

          button {
            &:not(:last-child) {
              margin-right: multiply-size(12px);
            }

            i {
              font-size: multiply-size(10px);
            }
          }
        }
      }
    }
  }

  .categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: multiply-size(8px);
    border-bottom: multiply-size(1px) solid #DCD9D0;
    margin-bottom: multiply-size(2px);

    .category {
      padding: 0 multiply-size(10px);
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        border-right: multiply-size(1px) solid #000000;
      }

      span {
        text-transform: uppercase;
        @include mikado;
        font-weight: bold;
        cursor: pointer;
        font-size: multiply-size(12px);

        &.active {
          color: $color-I;
          @include text-stroke();
        }
      }
    }
  }

  .ow-dialog {
    .bottom-bar {
      .pagination {
        .pages {
          width: multiply-size(20px);
          text-align: center;
        }

        button {
          i {
            font-size: multiply-size(10px);
          }
        }
      }
    }
  }

  table {
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;

    tr {
      th, td {
        padding: multiply-size(1px) multiply-size(3px);
        text-align: left;
        height: multiply-size(15px);

        &.column-prizes {
          width: multiply-size(50px);
          min-width: multiply-size(50px);
        }
      }
    }

    thead {
      th {
        @include archivo-narrow;
        font-size: multiply-size(8px);
        font-weight: normal;
      }
    }

    tbody {
      td {
        @include mikado;
        font-size: multiply-size(10px);
        font-weight: bold;
        border-bottom: multiply-size(1px) solid rgba($color-H, 0.4);
      }
    }

    .hide-element {
      visibility: collapse;

      td, th {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        line-height: 0 !important;
        height: 0 !important;
        border: none !important;
        overflow: hidden !important;

        .prizes {
          height: 0 !important;
          overflow: hidden !important;
        }
      }
    }
  }
  .wrapper-no-results{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .no-results{
      font-size: multiply-size(14px);
      font-weight: bold;
    }
  }
}
