@import "src/styles/variables/core/variables";
@import "../variables";

$colors: (
  $font: black,
  $background: $color-I,
  $box-shadow: black,
);

&.primary {
  @include generate-schema-dialog-color($colors);
}
