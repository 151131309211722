card-task-fake {
  @import "../../styles/card-task.base";

  .card {
    cursor: initial;

    .title {
      color: black;
      background: #ffffff;
    }
  }
}
