@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-custom-warehouse {

  .ow-dialog {
    &.window-a {
      height: calc(277px * var(--multiply));
      padding: multiply-size(20px) multiply-size(35px) 0 multiply-size(35px);
    }

    .top-bar {
      .title {
        font-size: calc(13px * var(--multiply));
        text-transform: unset;
      }

      .absolute-left {
        left: calc(24px * var(--multiply));
      }

      .mat-tooltip-trigger {
        @include mikado;
        padding: multiply-size(2px) multiply-size(9px) 0;
        background-color: #93EE09;
        border: multiply-size(2px) solid black;
        box-sizing: border-box;
        bottom: multiply-size(-3px);
        color: black;


        span, i {
          font-size: calc(10px * var(--multiply));
          text-shadow: none;
        }
      }
    }
  }

  .categories {
    height: 16%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .category {
      padding: 0 multiply-size(10px) multiply-size(4px);
      margin: 0;
      border-bottom: multiply-size(4px) solid transparent;
      text-align: center;
      box-sizing: border-box;

      &.active {
        border-bottom-color: #74C100;
      }

      &.new-products {
        color: $color-B;
        @include text-stroke();
      }

      p {
        @include mikado;
        font-size: multiply-size(10px);
        cursor: pointer;
        margin: 0;
      }
    }
  }

  .products-container {
    height: 84%;
  }

  .products-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: multiply-size(10px);
    height: 100%;

    .product {
      width: 20%;
      height: multiply-size(105px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      &:hover {
        cursor: pointer;

        .product-icon {
          filter: brightness(0.5);
        }
      }

      // hide separator in the last product
      &:nth-last-child(1 of :not(.empty-placeholder)):after {
        display: none;
      }

      &:not(:nth-child(5n)):not(.empty-placeholder):after {
        content: "";
        position: absolute;
        right: 0;
        height: 60%;
        top: 25%;
        background-color: #E1E1E1;
        width: multiply-size(1px);
      }

      &-name {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 multiply-size(10px);

        & > h4 {
          font-size: multiply-size(9px);
          @include archivo-narrow;
          text-align: center;
          font-weight: normal;
          margin: 0 0 multiply-size(4px);
        }
      }

      &-icon {
        margin-bottom: multiply-size(4px);
        width: multiply-size(38px);
        height: multiply-size(38px);
      }

      &-balance {
        @include mikado();
        font-weight: bold;
        margin: 0;
        font-size: multiply-size(10px);
      }
    }
  }

  .no-item {
    @include mikado();
    font-size: multiply-size(12px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: multiply-size(20px);
    box-sizing: border-box;
  }
}
