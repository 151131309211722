@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-splash-screen {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 800;
  pointer-events: none;

  .splash-bg {
    width: 100%;
    height: 100%;
    background-image: var(--asset--ui-background-png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0;
    transform: scale(1.5);
    transition: 0.3s;

    .logo {
      left: 50%;
      top: 50%;
      height: 350px;
      width: 460px;
      transform: translate(-50%, -50%);
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      background-image: var(--asset--ui-logo_big-png);
    }
  }

  &.active {
    pointer-events: all;
    background: #ffffff;

    .splash-bg {
      opacity: 1;
      transform: scale(1);
    }
  }

  .percentage {
    font-size: multiply-size(30px);
    color: white;
    @include futura-heavy;
    position: absolute;
    @include absolute-center-horizontal;
    bottom: multiply-size(40px);
  }

  loading {
    .loading-content {
      .relative {
        i {
          position: absolute !important;
          bottom: multiply-size(90px);
        }
      }
    }
  }

  @import "custom/style";
}
