@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

.card-container {
  width: multiply-size(76px);
  height: multiply-size(78px);
}

.card {
  cursor: pointer;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: multiply-size(2px) solid #000000;
  border-bottom: none;

  --choose-task-background: #EFE9E2;

  &.choose {
    .card-content {
      background: var(--choose-task-background);
      height: 100%;
      border-top-right-radius: multiply-size(3px);
      border-top-left-radius: multiply-size(3px);

      ow-object-svg {
        display: flex;
        justify-content: center;

        ::ng-deep {
          svg {
            width: 100%;
            height: multiply-size(25px);
          }
        }
      }
    }
  }

  &.card-a {
    .title {
      background-color: #63D0EF;
    }
  }

  &.card-b {
    .title {
      background-color: #00A6D6;
    }
  }

  &.card-c {
    .title {
      background-color: #005871;
    }
  }

  &.card-template-s3 {
    .progress-container {
      flex-direction: row-reverse;

      .fill {
        border: none;
        border-left: multiply-size(1px) solid $color-K;
        &.custom-color{
          background: linear-gradient(to bottom, #63D0EF 50%, #00A6D6 50%)
        }
      }
    }
  }

  .title {
    background-color: #003366;
    @include archivo-narrow();
    word-break: break-word;
    font-weight: bold;
    font-size: multiply-size(9px);
    color: $color-I;
    height: multiply-size(38px);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 multiply-size(2px);
    box-sizing: border-box;
    overflow: hidden;

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  .card-content {
    height: multiply-size(48px);
    width: 100%;
    background-color: #ffffff;

    .segment {
      height: 100%;
      position: relative;
      @include mikado();
      font-size: multiply-size(8px);
      color: black;
      font-weight: bold;
      box-sizing: border-box;
      padding-bottom: multiply-size(11px);

      .info {
        @include archivo-narrow();
        font-size: multiply-size(8px);
        text-align: center;
        padding: multiply-size(15px) multiply-size(5px) multiply-size(5px) multiply-size(5px);
        color: #000000;

        &.daily {
          padding-top: multiply-size(2px)
        }
      }

      .absolute-bottom {
        position: absolute;
        bottom: multiply-size(10px);
        left: 0;
        width: 100%;
        height: multiply-size(11px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-shadow: none;
        font-size: multiply-size(8px);
        color: #000000;
        background: #ffb300;
      }

      .amount {
        display: inline-block;
        width: 100%;
        margin-top: multiply-size(6px);
        font-size: multiply-size(9px);
        text-align: center;
      }

      .prizes {
        display: flex;
        flex-direction: column;

        .prize {
          margin-top: multiply-size(4px);
          display: flex;
          align-items: center;
          justify-content: center;


          m-ui-currency {
            margin-left: multiply-size(4px);
            span{
              @include archivo-narrow();
            }
          }
        }
        m-ui-currency{
          margin-left: multiply-size(2px);
          span{
            font-size: multiply-size(8px);
            color: black;
            text-shadow: unset;
            margin: unset;
          }
        }
      }
    }

    .progress-block {
      padding-top: multiply-size(6px);
      margin-bottom: multiply-size(1px);
      width: 100%;
      display: flex;
      align-items: center;
      //justify-content: center;
      .progress-container {
        width: multiply-size(50px);
        height: multiply-size(12px);
        box-sizing: border-box;
        border: multiply-size(1px) solid #000000;
        border-radius: multiply-size(50px);
        position: relative;
        margin-right: multiply-size(2px);
        margin-left: multiply-size(8px);
        display: flex;
        .fill {
          border: none;
          &.custom-color{
            background: linear-gradient(to bottom, #63D0EF 50%, #00A6D6 50%)
          }
        }
        .value {
          font-size: multiply-size(8px);
          @include absolute-center;
          color: #ffffff;
          @include text-stroke;
        }
      }
    }
  }
}

