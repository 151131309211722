@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

hud-player-profile-details {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  @include mikado();
  font-weight: bold;
  font-size: multiply-size(12px);

  a, a:hover {
    text-decoration: none;
  }

  .separator {
    width: multiply-size(200px);
    height: multiply-size(1px);
    margin: multiply-size(20px) 0;
    background: $color-K;
  }

  .buttons {
    margin-top: multiply-size(20px);
    display: flex;

    & > * {
      margin: 0 multiply-size(10px);
    }
  }

  .experience {
    margin: multiply-size(10px);
    font-size: multiply-size(10px);
  }

  #logout {
    color: $color-K;
    cursor: pointer;
    position: absolute;
    bottom: multiply-size(12px);
    font-size: multiply-size(12px);
    z-index: 1;
    font-weight: bold;
    right: multiply-size(14px);
  }
}
