@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

.ow-dialog {
  &.window-a {
    padding: multiply-size(16px) multiply-size(8px) multiply-size(8px) multiply-size(30px);
    box-sizing: border-box;
  }
}

.my-position {
  table {
    tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.prizes {
  display: flex;
  justify-content: flex-end;

  & > * {
    margin: 0 multiply-size(3px);
  }

  .value {
    @include mikado('!important');
    font-size: multiply-size(10px) !important;
    font-weight: bold;
  }
}

.no-items {
  height: 100%;
  font-size: multiply-size(12px);
  font-weight: bold;
  @include mikado;
}

ow-perfect-scrollbar {
  &.height-auto {
    height: auto;
  }
}

ow-perfect-scrollbar {
  width: 100% !important;
}

.my-position-container {
  td {
    border-bottom: none !important;
  }
}

.filter-container {
  button {
    &.checkbox {
      border-color: $color-H !important;
    }
  }
}

.filter-container {
  margin-top: multiply-size(10px);
  display: flex;
  align-items: center;

  .each-filter {
    display: flex;
    align-items: center;
    margin-right: multiply-size(10px);

    button {
      margin-right: multiply-size(8px);
    }

    span {
      @include mikado;
      font-weight: bold;
      font-size: multiply-size(10px);
    }
  }
}
