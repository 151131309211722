@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: #000000,
  $border: #000000,
  $background: (
    $normal: #00A6D6,
    $active: #005871,
    $hover: #63D0EF,
  ),
  $box-shadow: (
    $normal: #005871,
    $active: #02708F,
    $hover: #00A6D6,
  ),
  $font: (
    $normal: #FFFFFF,
    $active: #5892F2,
    $hover: #FFFFFF,
  )
);

&.primary {
  @include generate-schema-button-color($colors);
}
