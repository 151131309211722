@import "src/styles/functions";
@import "variables";

@mixin generate-schema-button-color($colors) {
  $background-colors: map-get($colors, $background);
  $box-shadow-colors: map-get($colors, $box-shadow);
  $font-colors: map-get($colors, $font);
  $border-color: map-get($colors, $border);
  $font-stroke-color: map-get($colors, $font-stroke);

  background: map-get($background-colors, $normal);
  color: map-get($font-colors, $normal);
  border-color: $border-color;
  text-shadow: unset;
  //@include text-stroke($font-stroke-color);

  &.square {
    background: map-get($background-colors, $normal);

    &.normal {
      box-shadow: inset 0 multiply-size(-4px) 0 0 map-get($box-shadow-colors, $normal);
      padding-bottom: multiply-size(4px);
    }

    &.small {
      box-shadow: inset 0 multiply-size(-3px) 0 0 map-get($box-shadow-colors, $normal);
      padding-bottom: multiply-size(3px);
    }
  }

  &.base {
    box-shadow: inset 0 multiply-size(-4px) 0 0 map-get($box-shadow-colors, $normal);
    padding-bottom: multiply-size(4px);
  }

  &.short {
    box-shadow: inset 0 multiply-size(-3px) 0 0 map-get($box-shadow-colors, $normal);
    padding-bottom: multiply-size(2px);
  }
  &:hover {
    background: map-get($background-colors, $hover);
    color: map-get($font-colors, $hover);
    &.square {
      background: map-get($background-colors, $hover);


      &.normal {
        box-shadow: inset 0 multiply-size(-4px) 0 0 map-get($box-shadow-colors, $hover);
        padding-bottom: multiply-size(4px);
      }

      &.small {
        box-shadow: inset 0 multiply-size(-3px) 0 0 map-get($box-shadow-colors, $hover);
        padding-bottom: multiply-size(3px);
      }
    }

    &.base {
      box-shadow: inset 0 multiply-size(-4px) 0 0 map-get($box-shadow-colors, $hover);
      padding-bottom: multiply-size(4px);
    }

    &.short {
      box-shadow: inset 0 multiply-size(-3px) 0 0 map-get($box-shadow-colors, $hover);
      padding-bottom: multiply-size(2px);
    }
  }
  &:active {
    background: map-get($background-colors, $active);
    color: map-get($font-colors, $active);

    &.square {
      &.normal {
        box-shadow: inset 0 multiply-size(4px) 0 0 map-get($box-shadow-colors, $active);
        padding-bottom: multiply-size(4px);
      }

      &.small {
        box-shadow: inset 0 multiply-size(3px) 0 0 map-get($box-shadow-colors, $active);
        padding-bottom:  multiply-size(3px);
      }
    }

    &.base {
      box-shadow: inset 0 multiply-size(4px) 0 0 map-get($box-shadow-colors, $active);
      padding-top: multiply-size(6px);
    }

    &.short {
      box-shadow: inset 0 multiply-size(3px) 0 0 map-get($box-shadow-colors, $active);
      padding-top: multiply-size(6px);
    }
  }


  i {
    color: inherit;
    text-shadow: unset;
    //@include text-stroke($font-stroke-color);
  }
}
