@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-mission-details {
  @import "variables";

  ow-perfect-scrollbar {
    width: multiply-size(10px, "100% + ") !important;

    .ps-content {
      @include archivo-narrow;
      font-size: multiply-size(10px);
      text-align: justify;
    }
  }

  .mission-icon {
    position: absolute;
    width: multiply-size(46px);
    height: multiply-size(46px);
    top: multiply-size(-30px);
    left: multiply-size(10px);

    img {
      width: 100%;
    }
  }

  .window-a {
    padding: multiply-size(30px) multiply-size(26px) multiply-size(22px) multiply-size(38px);
  }

  .title-left {
    position: absolute;
    display: flex;
    align-items: baseline;
    justify-content: center;
    @include mikado();
    @include text-stroke;
    font-weight: bold;
    left: multiply-size(60px);
    font-size: multiply-size(12px);
  }

  .mission-prizes {
    margin-top: multiply-size(8px);
    display: flex;

    & > * {
      margin-right: multiply-size(20px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .conditions {
    border-collapse: collapse;
    margin-top: multiply-size(10px);

    tr {
      height: multiply-size(40px);

      td {
        border-top: multiply-size(1px) solid $color-K;
        box-sizing: border-box;
        font-size: multiply-size(10px);
        @include mikado();
        font-weight: bold;
        color: $color-K;

        &.td-check {
          width: multiply-size(20px);
          padding-right: multiply-size(10px);

          .fas {
            font-size: multiply-size(15px);
            margin-left: multiply-size(3px);

            &.fa-check-circle {
              color: $color-B;
              @include text-stroke;
            }

            &.fa-circle {
              color: $color-F;
              @include text-stroke-grey;
              opacity: 0.5;
            }
          }
        }

        &.td-name {
          width: 100%;
          padding-right: multiply-size(10px);
          box-sizing: border-box;
        }

        &.td-condition {
          text-align: right;
          white-space: nowrap;
        }

        &.td-transfer {
          button {
            display: block;
            margin-left: multiply-size(5px);

            i {
              font-size: multiply-size(10px);
              transform: translateY(multiply-size(-1px));
            }
          }
        }
      }

      &:last-child {
        td {
          border-bottom: multiply-size(1px) solid $color-K;
        }
      }
    }
  }

  .timer {
    @include archivo-narrow;
    position: absolute;
    top: multiply-size(-16px);
    right: multiply-size(38px);
    font-size: multiply-size(10px);
    font-weight: bold;
    color: $color-I;
    @include text-stroke;
  }

  @import "custom/style";
}
