@import "src/styles/functions";
@import "src/styles/variables/core/variables";

#{$context-mobile} & {
}

#{$context-desktop} & {
  --multiply: 2;
}

#{$context-tablet} & {
  --multiply: 1.5;
}


