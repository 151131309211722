@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-question {
  .ow-dialog {
    display: flex;
    flex-direction: column;

    &.window-a {
      padding: multiply-size(13px) multiply-size(35px) multiply-size(15px) multiply-size(35px);

      &.question-details {
        padding: multiply-size(20px) multiply-size(100px) multiply-size(20px) multiply-size(100px);
      }
    }
  }

  .question-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .answers-container {
    flex: 1;
    overflow: hidden;
  }

  a {
    text-decoration: none;

    button {
      margin: 0 auto;

      &.attachment {
        i {
          position: absolute;
          top: 0;
          right: multiply-size(-20px);
          font-size: multiply-size(12px);
        }
      }
    }
  }

  .elem-flex {
    display: inline-flex;
    position: relative;
    width: 100%;
    text-align: left;
    align-items: center;
  }

  .title {
    @include archivo-narrow;
    font-size: multiply-size(12px);
    font-weight: bold;
    text-align: center;
  }

  .bottom-info {
    @include archivo-narrow;
    font-size: multiply-size(10px);
    font-weight: bold;
    text-align: center;
    margin: multiply-size(12px) 0;

    &.category {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }

  .question {
    margin-bottom: multiply-size(4px);
    font-size: multiply-size(11px);
    min-height: multiply-size(40px);
    @include archivo-narrow;

    button {
      &.checkbox {
        min-width: multiply-size(32px);
        min-height: multiply-size(32px);
        margin-right: multiply-size(10px);
        background-color: #ebf3fd;
      }

      i {
        font-size: multiply-size(16px);

        &.fa-square {
          font-size: multiply-size(12px);
          text-shadow: none;
          color: #3D81F2;
        }

        &.fa-check {
          &.all-correct {
            color: $color-K;
            text-shadow: none;
          }

          &.selected-correct {
            color: $color-S;
          }
        }

        &.fa-times {
          color: $color-G;
        }
      }
    }
  }

  .question-and-answers {
    .question-container {
      .title {
        margin-top: 0;
      }
    }
  }


  .question-details {
    .question-container {
      .title {
        margin: multiply-size(5px) 0;
      }

      .timer {
        text-align: center;
        @include mikado-black;
        font-size: multiply-size(12px);
      }

      .prizes {
        margin-bottom: multiply-size(15px);

        & > * {
          margin: 0 multiply-size(4px);
        }
      }

      .separator {
        margin: multiply-size(10px) 0;
        width: 100%;
        height: multiply-size(1px);
        background: #ffffff;
      }
    }
  }

  .margin-1 {
    margin: 0 0 multiply-size(15px) 0;
  }
}
