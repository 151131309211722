@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-hud-parameters {
  @import "variables";

  .ow-icon {
    &.chests {
      background-image: var(--asset--parameters-hud-chest-png);
    }

    &.museum {
      background-image: var(--asset--parameters-hud-exposure_value-png);
    }

    &.language {
      background-image: var(--asset--parameters-hud-language-png);
    }
  }

  .player-resources {
    display: flex;
    padding-top: multiply-size(4px);
    pointer-events: all;

    .bar {
      //@include text-stroke($color-X);
      min-width: multiply-size(51px);
      width: unset;
      height: multiply-size(16px);
      font-size: multiply-size(11px);
      display: flex;
      align-items: stretch;
      @include mikado();
      font-weight: 700;
      margin: multiply-size(6px);
      color: $font-color;
      position: relative;
      border:multiply-size(2px) solid black;
      background-color: $color-X;

      .icon-container {
        width: multiply-size(4px);
        background-color: $color-X;
        border-left: multiply-size(2px) solid $color-X;
        border-top: multiply-size(2px) solid $color-X;
        border-bottom: multiply-size(2px) solid $color-X;
        border-right: multiply-size(2px) solid $color-X;
        border-top-left-radius: multiply-size(6px);
        border-bottom-left-radius: multiply-size(6px);
        display: flex;
        justify-content: center;
        align-items: center;

        .ow-icon {
          height: multiply-size(20px);
          width: multiply-size(20px);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .resource-container {
        display: flex;
        flex: 1;
        padding-left: multiply-size(3px);
        padding-right: unset;
        justify-content: flex-end;
        align-items: center;
        background-color: $color-X;
        border-right: multiply-size(2px) solid $color-X;
        border-top: multiply-size(2px) solid $color-X;
        border-bottom: multiply-size(2px) solid $color-X;
        border-top-right-radius: multiply-size(6px);
        border-bottom-right-radius: multiply-size(6px);
        white-space: nowrap;
      }

      .delta {
        position: absolute;
        right: multiply-size(-2px);
        bottom: multiply-size(-4px);
        display: flex;


        span, i {
          transform: translateX(multiply-size(2px));
        }

        span {
          margin-right: multiply-size(1px);
        }

        i {
          font-size: multiply-size(14px);
        }

        &.green {
          span, i {
            color: $color-B;
            @include text-stroke($color-O);
          }

          &.delta {
            align-items: baseline;
          }
        }

        &.red {
          span, i {
            color: $color-G;
            @include text-stroke($color-H);
          }

          &.delta {
            align-items: center;
          }
        }
      }
    }
  }

  .tooltip-container {
    font-weight: normal;
  }
}

